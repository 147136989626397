import { Chains, createWeb3Auth } from '@kolirt/vue-web3-auth'

const chains = [Chains.mainnet]

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV !== 'production') {
  const { localhost } = Chains
  localhost.id = 31337
  chains.push(localhost)
}

export default createWeb3Auth({
  autoInit: true,
  projectId: '440135962d06f8f2501e8a4a111150a9',
  chains,
  autoConnect: true,
  disconnectUnknownChain: true,
  reconnectToChain: true,
  logEnabled: true,
  web3modalOptions: {
    themeMode: 'dark',
    themeVariables: {
      '--w3m-z-index': 1101,
      '--w3m-font-family': 'Share Tech Mono, monospace',
      '--w3m-accent': 'hsl(0, 0%, 100%)',
      '--w3m-color-mix': 'hsl(0, 0%, 100%)',
      '--w3m-color-mix-strength': '100%',
      '--w3m-font-size-master': '1rem',
      '--w3m-border-radius-master': '0rem',
      '--w3m-container-border-radius': '0rem',
      '--w3m-background-border-radius': '0rem',
      '--w3m-background-color': '#000',
      '--w3m-color-bg-1': '#000',
      '--w3m-overlay-backdrop-filter': 'blur(0.5rem) brightness(0.5)',
      '--w3m-overlay-background-color': 'hsla(0, 0%, 0%, 0.8)',
    },
  },
})
