<script setup>
import { computed, ref, watch } from 'vue'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { RouterLink, useRouter } from 'vue-router'

import GlitchText from '@/components/GlitchText.vue'

const userStore = useUserStore()
const { isAuthenticated, canShowConnect, connected } = storeToRefs(userStore)

const router = useRouter()
const routes = computed(() =>
  router
    .getRoutes()
    .filter((route) => route.meta.sidebar && !(route.meta.requiresAuth && !isAuthenticated.value)),
)
const primaryRoutes = computed(() => routes.value.filter((route) => !!!route.meta.inMenu))
const secondaryRoutes = computed(() => routes.value.filter((route) => route.meta.inMenu))

const isMenuOpen = ref(false)

watch(router.currentRoute, () => (isMenuOpen.value = false))
</script>

<template>
  <div
    class="mobile-bar p-2 has-background-primary-00-blurred"
    :class="{ 'is-connected': connected }"
    role="contentinfo"
  >
    <transition name="fade">
      <div v-if="isMenuOpen" class="is-clipped pb-0">
        <div class="navigation-links field is-grouped">
          <router-link
            v-for="route in secondaryRoutes"
            :key="route.path"
            :to="route.path"
            v-slot="{ isActive, href, navigate }"
            custom
          >
            <div class="control is-expanded">
              <a
                class="button is-fullwidth"
                :href="href"
                :aria-label="route.meta.name"
                :class="[isActive ? 'is-primary' : 'is-ghost']"
                @click="navigate"
              >
                <span class="icon">
                  <component :is="route.meta.icon" />
                </span>
                <span class="is-square-pixel-7 is-size-7">
                  {{ route.meta.name }}
                </span>
              </a>
            </div>
          </router-link>
        </div>

        <glitch-text :stacks="3">
          <div
            class="beta-tag is-flex is-align-items-center is-justify-content-center is-square-pixel-7 has-text-info is-size-7 my-0"
          >
            Beta 1.7.2
          </div>
        </glitch-text>
      </div>
    </transition>

    <div
      class="navigation-links buttons is-flex is-flex-wrap-nowrap is-justify-content-space-around mb-0"
    >
      <router-link
        v-for="route in primaryRoutes"
        :key="route.path"
        :to="route.path"
        v-slot="{ isActive, href, navigate }"
        custom
      >
        <a
          v-if="route.meta.sidebar"
          class="navigation-links-item button has-text-centered is-flex is-flex-direction-column"
          :href="href"
          :aria-label="route.meta.name"
          :class="[isActive ? 'is-primary' : 'is-ghost']"
          @click="navigate"
        >
          <p class="icon m-0">
            <component :is="route.meta.icon" />
          </p>
          <p class="is-square-pixel-7 is-size-7">
            {{ route.meta.name }}
          </p>
        </a>
      </router-link>

      <transition name="fade">
        <a
          v-if="!isAuthenticated && canShowConnect"
          class="navigation-links-item button is-flex is-flex-direction-column has-text-centered is-ghost has-text-danger"
          @click="userStore.connect"
          aria-label="Link"
        >
          <span class="icon m-0">
            <link-variant-icon />
          </span>
          <span class="is-square-pixel-7 is-size-7">Link</span>
        </a>
      </transition>

      <a
        ref="mobileMenuButton"
        class="navigation-links-item button is-flex is-flex-direction-column has-text-centered is-ghost"
        @click="isMenuOpen = !isMenuOpen"
      >
        <span class="icon m-0">
          <menu-icon v-if="!isMenuOpen" />
          <close-icon v-else />
        </span>
        <span class="is-square-pixel-7 is-size-7">Menu</span>
      </a>
    </div>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  height: 0;
}
</style>
