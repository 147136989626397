<template>
  <footer class="footer has-background-inherit">
    <div class="content has-text-centered">
      <p>© {{ new Date().getFullYear() }} Infrax Network</p>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  z-index: 10;
}
</style>
