const icons = import.meta.glob('./**/*.vue', { eager: true })

export default {
  install: (app) => {
    Object.entries(icons).forEach(([path, module]) => {
      // Get component name from path
      // e.g. './social/TelegramIcon.vue' -> 'TelegramIcon'
      const componentName = path.match(/\/([^/]+)\.vue$/)[1]
      app.component(componentName, module.default)
    })
  },
}
